import moment from 'moment';

const AuthHelper = {};

AuthHelper.isExpired = () => {
  const expiryDate = sessionStorage.getItem('expiry');
  return !expiryDate || !moment().isBefore(expiryDate);
};

AuthHelper.setExpiry = () => {
  sessionStorage.setItem('expiry', moment().add(1, 'day').toISOString());
};

export { AuthHelper };
