import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  compose, withHandlers, lifecycle, withState,
} from 'recompose';
import { withFormFields } from '../components/shared/hoc/withFormFields';
import { PasswordTextBox } from '../components/shared/TextBox';
import { Button } from '../components/shared/Button';
import { AuthHelper } from '../helpers/AuthHelper';
import { SCREEN_SIZE } from '../style';

const Cell = styled.div`
  padding: 0 1rem;
`;

const StyledLockPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const PasswordLabel = styled.div`
  padding: 1rem 0;
`;

const LockPageForm = styled.form`
  ${SCREEN_SIZE.LG} {
    display: flex;
    align-items: center;
  }
`;

const StyledInvalidPassword = styled.div`
  color: red;
  text-align: center;
  padding: .5rem 0;
`;

const LockPage = ({
  formFields,
  setFormField,
  onSubmitHandler,
  isValid,
}) => (
  <StyledLockPage>
    <div>
      { isValid === false && <StyledInvalidPassword>Invalid Password</StyledInvalidPassword>}
      <LockPageForm
        onSubmit={ev => {
          ev.preventDefault();
          return onSubmitHandler();
        }}
      >
        <Cell>
          <PasswordLabel>
            Password
          </PasswordLabel>
        </Cell>
        <Cell>
          <PasswordTextBox
            value={formFields.passcode}
            onChange={passcode => setFormField('passcode', passcode)}
          />
        </Cell>
        <Cell>
          <Button>
            Unlock
          </Button>
        </Cell>
      </LockPageForm>
    </div>
  </StyledLockPage>
);

LockPage.defaultProps = {
  isValid: null,
};

LockPage.propTypes = {
  // eslint-disable-next-line
  formFields: PropTypes.object.isRequired,
  setFormField: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const EnhancedLockPage = compose(
  withState('isValid', 'setIsValid'),
  withFormFields({
    initialValues: () => ({
      passcode: '',
    }),
  }),
  withHandlers({
    onSubmitHandler: ({ formFields, setIsValid }) => () => {
      if (formFields.passcode === 'hc_2018') {
        setIsValid(true);
        AuthHelper.setExpiry();
        navigate('/');
      } else {
        setIsValid(false);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      if (!AuthHelper.isExpired()) {
        navigate('/');
      }
    },
  }),
)(LockPage);

export default EnhancedLockPage;